@import url('https://fonts.googleapis.com/css2?family=Delius&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.App {
  font-family: 'Quicksand', sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes animateFlip {
  0% {
    opacity: 0;
    transform: rotateX(-120deg) scale(0.9,0.9);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg) scale(1,1);
  }
}

@keyframes animatePop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.delay-_5s {
  animation-delay: 0.5s;
}

.delay-1s {
  animation-delay: 1s;
}

.delay-1_5s {
  animation-delay: 1.5s;
}

.scene_element--flip {
  animation-name: animateFlip;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation-duration: 1s !important;
}

.scene_element--pop {
  animation-name: animatePop;
}

/** Basic styles for an animated element */
.scene_element {
  animation-duration: 0.75s;
  transition-timing-function: ease !important;
  animation-fill-mode: both !important;
}

/** An element that fades in */
.scene_element--fadein {
  animation-name: fadeIn;
}

/** An element that fades in and slides up */
.scene_element--fadeinup {
  animation-name: fadeInUp;
}

/** An element that fades in and slides from the right */
.scene_element--fadeinright {
  animation-name: fadeInRight;
}

*:focus {
  box-shadow: none !important;
}