@import url('https://fonts.googleapis.com/css2?family=Delius&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.blog h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 32px !important;
  font-weight: bold !important;
  padding: 12px 0 !important;
}

.blog h2 {
  font-size: 24px !important;
  font-weight: bold !important;
  padding: 8px 0 !important;
}

.blog h3 {
  font-size: 20px !important;
  font-weight: bold !important;
  padding: 4px 0 !important;
}

.blog pre code {
  display: block !important;
  background: none !important;
  white-space: pre-wrap !important;
  overflow-x: hidden !important;
  max-width: 100% !important;
  min-width: 100px !important;
  padding: 0 !important;
  word-break: break-word !important;
}

.blog p {
  font-size: 18px !important;
  white-space: pre-wrap !important;
  padding-bottom: 12px !important;
  padding-top: 0px !important;
}

.blog-code-block {
  font-family: monospace !important;
  font-size: 14px !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none !important;
  scrollbar-width: 1px !important;
}

.blog code {
  font-family: monospace !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.blog ol {
  font-size: 18px !important;
  list-style-position: outside !important;
  padding-left: 24px !important;
}

.blog ul {
  font-size: 18px !important;
  list-style-type: square !important;
  list-style-position: outside !important;
  list-style-image: none !important;
  padding-left: 24px !important;
}

.blog li {
  padding: 4px 0 !important;
}

.blog li ul {
  margin: 0 !important;
}

.blog li ul li {
  font-size: 18px !important;
  list-style-type: circle !important;
}

.blog blockquote { 
  border-left: #ddd solid 4px !important;
  padding: 10px !important; 
  background: rgba(15, 0, 25, 0.12) !important;
  margin-top: 10px !important; 
  margin-bottom: 10px !important; 
}

.blog blockquote p { 
  padding-bottom: 5px !important;
  margin-top: 10px !important; 
  margin-bottom: 10px !important; 
}

.blog a {
  color: #BE24CB !important;
}

.blog a:visited {
  color: #741D7B !important;
}